import type { PropType, VNode } from 'vue';
import './Tip.css';

export type TipType = 'caution' | 'neutral' | 'warning' | 'success';

export default defineComponent({
  name: 'Tip',
  props: {
    title: {
      default: undefined,
      type: String as PropType<null | string | undefined>,
    },
    type: {
      default: 'neutral',
      type: String as PropType<TipType>,
    },
  },
  render(): VNode | null {
    if (!this.$slots.default) return null;
    return (
      <aside class="tip" data-type={this.type}>
        {this.$slots.icon?.()}
        <div class="wrapper">
          {this.title && <strong class="title">{this.title}</strong>}
          <div class="content">{this.$slots.default()}</div>
        </div>
      </aside>
    );
  },
});
