import type { VNode } from 'vue';
import Button from '@/components/Button';
import IconPlay from '@/assets/icons/icon-play.svg?component';
import IconPause from '@/assets/icons/icon-pause.svg?component';

const subscriptionSuspensionTexts = {
  active: {
    heading: 'subscription_toggle.active.heading',
    description: 'subscription_toggle.active.description',
    modalTitle: 'subscription_toggle.active.modal_title',
    modalContent: 'subscription_toggle.active.modal_content',
    modalActionCancel: 'subscription_toggle.active.modal_action_cancel',
    modalActionSuspend: 'subscription_toggle.active.modal_action_suspend',
    noticeSuccess: 'subscription_toggle.active.notice_success',
  },
  inactive: {
    heading: 'subscription_toggle.inactive.heading',
    description: 'subscription_toggle.inactive.description',
    modalTitle: 'subscription_toggle.inactive.modal_title',
    modalContent: 'subscription_toggle.inactive.modal_content',
    modalActionCancel: 'subscription_toggle.inactive.modal_action_cancel',
    modalActionSuspend: 'subscription_toggle.inactive.modal_action_suspend',
    noticeSuccess: 'subscription_toggle.inactive.notice_success',
  },
};

export default defineComponent({
  name: 'SubscriptionSuspensionToggle',
  setup() {
    const { isFetchedSubscriptions, isSubscriptionActive, subscription } =
      useCustomerSubscriptions();
    const { setSubscriptionSuspended } = useDeliveriesApi();
    const noticeStore = useNoticeStore();
    const modalStore = useModalStore();
    const { t } = useI18n();

    const texts = computed(() => {
      return isSubscriptionActive.value
        ? subscriptionSuspensionTexts.active
        : subscriptionSuspensionTexts.inactive;
    });

    const toggleSubscriptionSuspension = async (): Promise<void> => {
      const newValue = isSubscriptionActive.value;
      if (!subscription.value) {
        return;
      }
      try {
        await setSubscriptionSuspended(newValue, subscription.value.id);

        noticeStore.addNotice({
          text: t(texts.value.noticeSuccess),
          type: 'success',
        });
      } catch (error) {
        noticeStore.addNotice({
          text: t('subscription_toggle.error'),
          type: 'caution',
        });
      }
    };

    const openModal = (): void => {
      modalStore.setModal({
        title: t(texts.value.modalTitle),
        content: (
          <div class="flex flex-col gap-4">
            <p>{t(texts.value.modalContent)}</p>
          </div>
        ),
        actions: (
          <div class="flex w-full justify-center gap-4">
            <Button class="button-stroke" onClick={() => modalStore.close()}>
              {t(texts.value.modalActionCancel)}
            </Button>
            <Button
              class="button-primary"
              onClick={() => {
                toggleSubscriptionSuspension();
                modalStore.close();
              }}
            >
              {t(texts.value.modalActionSuspend)}
            </Button>
          </div>
        ),
      });
    };

    return {
      openModal,
      disabled: !isFetchedSubscriptions.value,
      isSubscriptionActive,
      texts,
    };
  },
  render(): VNode {
    return (
      <div
        class={`-mx-4 flex items-center p-4 transition-colors md:-mx-8 ${
          this.isSubscriptionActive
            ? 'bg-rb-green text-white'
            : 'bg-rb-light-red text-rb-bright-red'
        }`}
      >
        <div class="flex flex-1 flex-col">
          <p class="font-bold">{this.$t(this.texts.heading)}</p>
          <p
            class={`text-xs ${
              this.isSubscriptionActive ? 'text-rb-light-gray' : 'text-rb-gray'
            }`}
          >
            {this.$t(this.texts.description)}
          </p>
        </div>
        <Button
          onClick={this.openModal}
          disabled={this.disabled}
          class="!h-full"
        >
          {this.isSubscriptionActive ? (
            <IconPause height="48" width="48" class="icon" />
          ) : (
            <IconPlay height="48" width="48" class="icon" />
          )}
        </Button>
      </div>
    );
  },
});
