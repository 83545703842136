import type { VNode } from 'vue';
import { PortableText } from '@portabletext/vue';
import { format } from 'date-fns';
import { Language } from '@ruokaboksi/api-client';
import SanityPicture from '@/components/SanityPicture';
import type { DeliveryWeek } from '@/models';
import IconCampaign from '@/assets/icons/icon-campaign.svg?component';
import Tip from '@/components/Tip';
import WeekDiscountBadge from '@/components/WeekDiscountBadge';
import SubscriptionSuspensionToggle from '../SubscriptionSuspensionToggle';
import './DeliveryWeekCalendar.css';
import {
  boxTypeDescriptionToLocaleString,
  deliveryWeekToLocaleString,
} from './utils';

export default defineComponent({
  name: 'DeliveryWeekCalendar',
  setup() {
    const { campaign, hasCampaign } = useCustomerCampaigns();
    const {
      isSubscriptionActive,
      isSubscriptionSuspended,
      subscriptionId,
      subscription,
    } = useCustomerSubscriptions();
    const { setWeekPause } = useDeliveriesApi();
    const { deliveryWeeks, isSelectedDeliveryWeekPaused } = useDeliveryWeeks();
    const noticeStore = useNoticeStore();
    const { boxTypes } = useBoxTypes();
    const { t, locale } = useI18n();

    /**
     * List of delivery weeks with one entry removed if all
     * weeks are paused. Otherwise show all 13 entries.
     */
    const deliveryWeeksFiltered = computed<DeliveryWeek[]>(() => {
      if (!deliveryWeeks.value) return [];
      return deliveryWeeks.value.slice(2, deliveryWeeks.value.length);
    });

    /**
     * Determines if a label is the last one on the list.
     * @returns it's the last entry on the list.
     */
    const isLastEntry = (index: number): boolean =>
      index + 1 === deliveryWeeksFiltered.value.length;

    /** Handles toggle events for delivery weeks. */
    const handleDeliveryWeekToggle = async (event: Event): Promise<void> => {
      event.preventDefault();
      const input = event.target as HTMLInputElement;
      const newValue = input.checked;
      const deliveryWeek = deliveryWeeks.value?.find(
        (d) => d.weekNumberString === input.value
      );
      if (!deliveryWeek || !subscriptionId.value) {
        return;
      }
      const isEditable = !!deliveryWeek?.editable;
      if (!isEditable) return;

      try {
        await setWeekPause(
          !newValue,
          subscriptionId.value,
          new Date(deliveryWeek.paymentDate)
        );
      } catch (error) {
        return noticeStore.addNotice({
          text: `${t('delivery_calendar.payment_failed_week', {
            weekNumber: deliveryWeek.weekNumberString,
          })} ${t('delivery_week.payment_failed')}`,
          type: 'caution',
        });
      }

      const areAllOtherWeeksPaused = deliveryWeeksFiltered.value
        ?.filter((_week, index) => !isLastEntry(index))
        .filter((week) => week.paymentDate !== deliveryWeek.paymentDate)
        .every((el) => el.paused);

      if (areAllOtherWeeksPaused && !newValue && isSubscriptionActive.value) {
        const lastDeliveryWeek = deliveryWeeksFiltered?.value?.find(
          (_week, index) => isLastEntry(index)
        );

        const lastDeliveryDateFormatted = format(
          lastDeliveryWeek!.deliveryDate,
          'dd.MM.yyyy'
        );

        noticeStore.addNotice({
          text: `${t('delivery_calendar.next_10_weeks_paused', {
            deliveryDate: lastDeliveryDateFormatted,
          })} ${t('delivery_calendar.pause_all_weeks')}`,
          timeout: 10000,
        });
      }
    };

    return {
      boxTypes,
      campaign,
      deliveryWeeksFiltered,
      handleDeliveryWeekToggle,
      hasCampaign,
      isLastEntry,
      isSelectedDeliveryWeekPaused,
      isSubscriptionActive,
      isSubscriptionSuspended,
      subscription,
      locale,
    };
  },
  render(): VNode {
    return (
      <aside class="delivery-week-calendar">
        <div class="-mt-4 md:-mt-7 lg:-mt-10">
          <SubscriptionSuspensionToggle />

          {this.hasCampaign &&
            (this.campaign?.mainImage ? (
              <div class="mt-2 flex w-full justify-center">
                <SanityPicture
                  src={this.campaign.mainImage.sizes.medium}
                  alt={this.campaign.title}
                  class="max-h-64 max-w-96"
                />
              </div>
            ) : (
              <Tip
                title={this.campaign?.title}
                type={
                  !this.isSubscriptionActive ||
                  (this.isSelectedDeliveryWeekPaused &&
                    this.isSubscriptionActive)
                    ? 'caution'
                    : 'success'
                }
                v-slots={{ icon: () => <IconCampaign class="icon" /> }}
              >
                {this.campaign?.description ? (
                  <PortableText value={this.campaign.description} />
                ) : null}
              </Tip>
            ))}
          <form class="my-6">
            <p class="text-large">{`${
              this.isSubscriptionActive
                ? `${this.$t('delivery_calendar.pause_deliveries')}`
                : `${this.$t('delivery_calendar.activate_deliveries')}`
            }`}</p>

            {this.boxTypes?.length && this.deliveryWeeksFiltered?.length
              ? this.deliveryWeeksFiltered.map((deliveryWeek, index) => {
                  const isEntryDisabled =
                    deliveryWeek.locked || this.isLastEntry(index);
                  return (
                    <label
                      class="label"
                      data-disabled={isEntryDisabled}
                      data-editable={!deliveryWeek.locked}
                      data-paused={deliveryWeek.paused}
                    >
                      <input
                        checked={!deliveryWeek.paused}
                        class="input-radio"
                        disabled={isEntryDisabled}
                        onChange={this.handleDeliveryWeekToggle}
                        type="checkbox"
                        value={deliveryWeek.weekNumberString}
                      />
                      <span class="label-text block">
                        {deliveryWeekToLocaleString(
                          deliveryWeek,
                          this.locale as Language
                        )}
                        <br />
                        {boxTypeDescriptionToLocaleString(
                          this.boxTypes,
                          deliveryWeek,
                          deliveryWeek?.recipes?.length ||
                            this.subscription?.defaultNumberOfRecipes ||
                            0
                        )}
                      </span>
                      {deliveryWeek.editable && (
                        <WeekDiscountBadge deliveryWeek={deliveryWeek} />
                      )}
                    </label>
                  );
                })
              : null}
          </form>
        </div>
      </aside>
    );
  },
});
